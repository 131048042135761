import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import MovieRow from './components/MovieRow';
import MovieTrailer from './components/MovieTrailer';
import ActorDetail from './components/ActorDetail';
import SearchResult from './components/SearchResult';
import Login from './components/Login';
import Register from './components/Register';
import FavoriteMovies from './components/FavoriteMovies';
import FavoriteActors from './components/FavoriteActors';
import MovieGenres from './components/MovieGenres';
import './App.css';
import { auth } from './firebase/config';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';

function App() {
  const location = useLocation();
  // Định nghĩa các thể loại phim với ID chính xác từ TMDB
  const movieCategories = [
    { id: 28, title: "Phim Hành Động" },      // Action
    { id: 12, title: "Phim Phiêu Lưu" },      // Adventure
    { id: 16, title: "Phim Hoạt Hình" },      // Animation
    { id: 35, title: "Phim Hài" },            // Comedy
    { id: 80, title: "Phim Hình Sự" },        // Crime
    { id: 18, title: "Phim Chính Kịch" },     // Drama
    { id: 14, title: "Phim Giả Tưởng" },      // Fantasy
    { id: 27, title: "Phim Kinh Dị" },        // Horror
    { id: 10749, title: "Phim Lãng Mạn" },    // Romance
    { id: 878, title: "Phim Khoa Học" }       // Science Fiction
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [user, setUser] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  // Hàm search với debounce
  const searchMovies = useCallback(async (query, page = 1) => {
    if (query.trim() === '') {
      setSearchResults([]);
      return;
    }

    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/search/movie?api_key=9ecf1d7370506c5ed53c9c28ec8bb100&language=vi-VN&query=${query}&page=${page}`
      );
      const data = await response.json();
      setTotalPages(data.total_pages);

      if (page === 1) {
        setSearchResults(data.results);
      } else {
        setSearchResults((prevResults) => [...prevResults, ...data.results]);
      }
    } catch (error) {
      console.error('Lỗi khi tìm kiếm phim:', error);
    }
  }, []);

  // Xử lý debounce search term
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000); // Đợi 1 giây

    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);

  // Gọi API khi debouncedSearchTerm thay đổi
  useEffect(() => {
    if (debouncedSearchTerm) {
      searchMovies(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, searchMovies]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
        if (currentPage < totalPages) {
          setCurrentPage((prevPage) => {
            const nextPage = prevPage + 1;
            searchMovies(debouncedSearchTerm, nextPage);
            return nextPage;
          });
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentPage, totalPages, debouncedSearchTerm, searchMovies]);

  // Thêm useEffect để xử lý click outside dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success('Đăng xuất thành công!');
      setIsDropdownOpen(false);
    } catch (error) {
      toast.error('Có lỗi xảy ra khi đăng xuất');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <a href="/">MOVIE TRAILERS</a>
        </div>
        <nav className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <a href="/" className="nav-link">Home</a>
          <a href="/" className="nav-link">Features</a>
          <a href="/" className="nav-link">Support</a>
          <a
            href="https://play.google.com/store/apps/details?id=com.pblorange.movietrailers"
            className="download-button"
            style={{ display: 'flex', padding: 0 }}
          >
            <img src="/googleplay.png" alt="Download on Google Play" />
          </a>



        </nav>
        {location.pathname !== '/login' && location.pathname !== '/register' && (
          <div className='top-right-nav'>
            <div className="search-container">
              <input
                type="text"
                placeholder="Tìm kiếm phim..."
                className="search-input"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="avatar-container" ref={dropdownRef}>
              <div
                className="avatar-icon"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {user && user.photoURL ? (
                  <img
                    src={user.photoURL}
                    alt="User avatar"
                    className="user-avatar"
                  />
                ) : (
                  <img
                    src={'https://i.pinimg.com/564x/a4/3e/e6/a43ee6d3e310564af22b71bdfb1a52e7.jpg'}
                    alt="User avatar"
                    className="user-avatar"
                  />
                )}
              </div>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  {!isLoggedIn ? (
                    <>
                      <Link to="/login" className="dropdown-item" onClick={() => setIsDropdownOpen(false)}>Đăng nhập</Link>
                      <Link to="/register" className="dropdown-item" onClick={() => setIsDropdownOpen(false)}>Đăng ký</Link>
                    </>
                  ) : (
                    <>
                      <div className="user-info dropdown-item">
                        <img src={user?.photoURL || "https://i.pinimg.com/564x/a4/3e/e6/a43ee6d3e310564af22b71bdfb1a52e7.jpg"} alt="avatar" className="mini-avatar" />
                        <span>{user?.displayName || user?.email}</span>
                      </div>
                      <Link to="/favorite-movies" className="dropdown-item" onClick={() => setIsDropdownOpen(false)}>Phim đã thích</Link>
                      <Link to="/favorite-actors" className="dropdown-item" onClick={() => setIsDropdownOpen(false)}>Diễn viên đã thích</Link>
                      <div className="dropdown-divider"></div>
                      <button onClick={handleLogout} className="dropdown-item logout-btn">
                        Đăng xuất
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

        )}
      </header>
      <Routes>
        <Route path="/" element={
          <main>
            {searchTerm ? (
              <SearchResult movies={searchResults} />
            ) : (
              movieCategories.map((category) => (
                <MovieRow
                  key={category.id}
                  title={category.title}
                  genreId={category.id}
                />
              ))
            )}
          </main>
        } />
        <Route path="/movie/:movieId" element={searchTerm ? (
          <SearchResult movies={searchResults} />
        ) : (<MovieTrailer />)} />
        <Route path="/actor/:actorId" element={searchTerm ? (
          <SearchResult movies={searchResults} />
        ) : (<ActorDetail />)} />
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/favorite-movies" element={searchTerm ? (
          <SearchResult movies={searchResults} />
        ) : (<FavoriteMovies />)} />
        <Route path="/favorite-actors" element={searchTerm ? (
          <SearchResult movies={searchResults} />
        ) : (<FavoriteActors />)} />
        <Route path="/genres/:genreId" element={searchTerm ? (
          <SearchResult movies={searchResults} />
        ) : (<MovieGenres />)} />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}